import { ReactNode, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@root/hooks'
import { MetaTagsProps } from '@root/types'
import PlaceholderLoader from '@root/components/Loader/PlaceholderLoader'
import { PROTECTED_PAGES_PATHS, UNPROTECTED_PAGES_PATHS } from '@root/constants'
import { segmentApi, sessionStorageApi } from '@root/api'
import { useAuthTemporalStore } from '@root/store'
import { MetaTags } from './MetaTags'

interface GuestLayoutProps extends MetaTagsProps {
  children: ReactNode
}

const GuestLayout = (props: GuestLayoutProps): JSX.Element => {
  const { children, title } = props
  const { status } = useAuth()
  const router = useRouter()
  const { pastStates } = useAuthTemporalStore((state) => state)
  // check if previous status is has `unauthorized` status
  const isPreviousStatusUnauthorized = pastStates.some(
    (pastState) => pastState.status === 'unauthorized'
  )
  const marketplaceCategoryInfo = sessionStorageApi.get('category')

  const shouldDisplayLoader: boolean =
    status === 'loading' ||
    // if current status is `authorized` and previous status has `unauthorized` state,
    // mark `isPreviousStatusUnauthorized` as the opposite to prevent displaying loader
    (status === 'authorized' && !isPreviousStatusUnauthorized)

  // exception PATH doesn't requires to be logged in and not redirect to dashboard
  const exceptionPath = [
    UNPROTECTED_PAGES_PATHS.mfaOptions,
    UNPROTECTED_PAGES_PATHS.mfaCode,
  ]

  useEffect(() => {
    segmentApi.page()
  }, [])

  useEffect(() => {
    /**
     * @example /login?taxfiling=true
     */
    if (router.query.taxfiling === 'true') {
      sessionStorageApi.set('taxfiling', 'true')
    }
    if (router.query.taxreferrer) {
      sessionStorageApi.set('taxreferrer', router.query.taxreferrer.toString())
    }
  }, [router.query])

  // redirect to dashboard if user is logged in
  useEffect(() => {
    if (status === 'authorized' && !exceptionPath.includes(router.pathname)) {
      if (sessionStorageApi.get('taxfiling')) {
        router.push('/tax-filing')
        // check if marketplace category is present in session storage
      } else if (marketplaceCategoryInfo) {
        router.push({
          pathname: PROTECTED_PAGES_PATHS.offers,
          query: JSON.parse(marketplaceCategoryInfo),
        })
      } else {
        router.push(PROTECTED_PAGES_PATHS.home)
      }
    }
  }, [status])

  return (
    <>
      <MetaTags title={title} />
      {shouldDisplayLoader ? <PlaceholderLoader /> : children}
    </>
  )
}

export { GuestLayout }
