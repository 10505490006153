import { useEffect, useState } from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useTheme } from 'styled-components'
import { useSearchParams } from 'next/navigation'
import { GuestLayout } from '@root/layouts'
import { UNPROTECTED_PAGES_PATHS } from '@root/constants'
import { useUIStore } from '@root/store'
import LANDING_LABEL from '../Landing/labels'
import {
  Container,
  HeaderContainer,
  HeaderLogo,
  HeaderButtonsView,
  HeaderSigninButton,
  HeaderSignupButton,
  DetailContainerView,
  CenteredView,
  LeftDetailView,
  MainTitle,
  DescriptionTitle,
  BottomButtonContainer,
  SigninButton,
  SignupContainerView,
  NoAccountLabel,
  SignupLink,
  RightDetailView,
  MobileViewImage,
  DesktopViewImage,
} from './index.styles'
import headerLogo from 'public/assets/svgs/ml-logo-white.svg'
import dashboardMobile from 'public/assets/images/ml-dashboard-mobile.webp'
import dashboardDesktop from 'public/assets/images/ml-dashboard-desktop-full-image.webp'
// import dashboardDesktop from 'public/assets/images/ml-dashboard-desktop-full-image.png'
// import dashboardMobile from 'public/assets/images/ml-dashboard-mobile.png'

const LandingPage: NextPage = () => {
  const theme = useTheme()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const searchParams = useSearchParams()
  const sessionTimeout = searchParams.get('sessionTimeout')

  const setShowNotificationModal = useUIStore(
    (state) => state.setShowNotificationModal
  )

  const onClickSignin = (): void => {
    setIsLoading(true)
    router.push('/login')
  }

  useEffect(() => {
    setShowNotificationModal(sessionTimeout === 'true' ? true : false, {
      text: (
        <div>
          Oops! Your session has expired due to inactivity.
          <br /> Please sign in to continue, or sign up to access exclusive
          features.
        </div>
      ),
    })

    return () => {
      setShowNotificationModal(false)
    }
  }, [sessionTimeout])

  return (
    <GuestLayout title="Log In">
      <Container>
        <HeaderContainer>
          <HeaderLogo>
            <Image
              src={headerLogo}
              alt="MoneyLion Logo"
              className="moneylion-logo"
              width={180}
              height={40}
            />
          </HeaderLogo>
          <HeaderButtonsView>
            <HeaderSigninButton
              size="large"
              onClick={onClickSignin}
              style={{ color: theme.colors?.['content-white'] }}
            >
              {LANDING_LABEL.sign_in}
            </HeaderSigninButton>

            <HeaderSignupButton
              onClick={() =>
                router.push(UNPROTECTED_PAGES_PATHS.signUp.organic)
              }
            >
              {LANDING_LABEL.signup_for_free}
            </HeaderSignupButton>
          </HeaderButtonsView>
        </HeaderContainer>
        <DetailContainerView>
          <LeftDetailView>
            <CenteredView>
              <MainTitle size="large">{LANDING_LABEL.title}</MainTitle>
              <DescriptionTitle>{LANDING_LABEL.description}</DescriptionTitle>
              <BottomButtonContainer>
                <SigninButton
                  data-testid="button_landing_signin"
                  onClick={onClickSignin}
                  loading={isLoading}
                >
                  {LANDING_LABEL.sign_in}
                </SigninButton>
                <SignupContainerView>
                  <NoAccountLabel>
                    {LANDING_LABEL.dont_have_account}
                  </NoAccountLabel>
                  <SignupLink
                    href={UNPROTECTED_PAGES_PATHS.signUp.organic}
                    style={{ color: 'white' }}
                  >
                    {LANDING_LABEL.signup_for_free}
                  </SignupLink>
                </SignupContainerView>
              </BottomButtonContainer>
            </CenteredView>
          </LeftDetailView>
          <RightDetailView>
            <MobileViewImage>
              <Image
                src={dashboardMobile}
                alt="MoneyLion Dashboard Mobile"
                className="moneylion-logo-mobile"
                width={375}
              />
            </MobileViewImage>
            <DesktopViewImage>
              <Image
                width={964}
                height={708}
                src={dashboardDesktop}
                alt="MoneyLion Dashboard Desktop"
              />
            </DesktopViewImage>
          </RightDetailView>
        </DetailContainerView>
      </Container>
    </GuestLayout>
  )
}

export default LandingPage
