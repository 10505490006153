import { NextPage } from 'next'
import { GuestLayout } from '@root/layouts'
import LandingPage from '../experiences/Landing'

const IndexPage: NextPage = () => {
  const title = 'Dashboard'

  return (
    <GuestLayout title={title}>
      <LandingPage />
    </GuestLayout>
  )
}

export default IndexPage
