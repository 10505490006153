import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { init } from '@moneylion/auth-js'
import { useAuthStore } from '@root/store'
import {
  formats,
  refreshAccessToken,
  validateToken,
  observabilityUtils,
} from '@root/utils'
import { sessionStorageApi, userApi } from '@root/api'
import { generateUniqueDeviceId } from '@root/helpers'
import IUser from '@root/types/User'
import { AuthStatus } from '@root/types'
import { PROTECTED_PAGES_PATHS } from '@root/constants'
import { ENV } from '@root/config'
import {
  getCommonAuthCookieDomain,
  getCommonAuthRedirectUri,
} from '@root/utils/commonAuth'

export interface UseAuthProps {
  user: IUser | null
  status: AuthStatus
  token: string
}

export const setupChatbot = async (
  user: IUser,
  token: string
): Promise<boolean> => {
  const { firstName: userFirstName, lastName: userLastName, email } = user
  const firstName = formats.string.capitalizeFirstLetter(userFirstName)
  const lastName = formats.string.capitalizeFirstLetter(userLastName)
  const deviceId = await generateUniqueDeviceId()
  const chatbot = sessionStorageApi.get('chatbot') || ''
  const shouldOpenChatBot = chatbot === 'start'

  if (window.adaSettings) {
    adaEmbed.start({
      handle: 'moneylion',
      language: 'en',
      metaFields: {
        email,
        name: `${firstName} ${lastName}`,
        phonenumber: user?.phoneNumber?.number,
        source: 'webApp',
        deviceId,
      },
      sensitiveMetaFields: {
        auth_token: token,
      },
      adaReadyCallback: () => {
        if (shouldOpenChatBot) {
          sessionStorageApi.remove('chatbot')
          adaEmbed.toggle()
        }
      },
    })
  }

  return true
}

export const setupAuthJs = async (): Promise<void> => {
  if (useAuthStore.getState().auth) return

  const initPayload = {
    clientId: ENV.COMMON_AUTH_CLIENT_ID,
    redirectUri: getCommonAuthRedirectUri(),
    clientSecret: ENV.COMMON_AUTH_CLIENT_SECRET,
    deviceId: await generateUniqueDeviceId(),
    authDomain: ENV.COMMON_AUTH_DOMAIN,
    scope: ENV.COMMON_AUTH_SCOPE,
    cookieDomain: getCommonAuthCookieDomain(),
  }

  const auth = await init(initPayload)
  useAuthStore.setState({ auth })
}

/**
 * useAuth hook
 * Flow process:
 * 1 Check if `t` query param & `user` exists
 * 1.1 If `t` query param exists, convert exchange token to access token
 * 1.2 then setup user, return true
 *
 * 2. Check if token is valid
 * 3. If token is valid, check if user is authenticated
 * 4. If user is authenticated, return true
 * 5. If user is not authenticated, return false
 * 6. If token is not valid, return false
 */
const useAuth = (): UseAuthProps => {
  const getAuthenticatedUser = useAuthStore(
    (state) => state.getAuthenticatedUser
  )
  const user = useAuthStore((state) => state.user)
  const status = useAuthStore((state) => state.status)
  const getAccessToken = useAuthStore((state) => state.getAccessToken)
  const setAccessToken = useAuthStore((state) => state.setAccessToken)
  const setStatus = useAuthStore((state) => state.setStatus)
  const router = useRouter()

  let token = getAccessToken()
  const tempToken = router.query.t as string

  const checkAuth = useCallback(async (): Promise<boolean> => {
    // it is possible that auth-js is not setup yet during the first getAccessToken()
    // so we setup auth-js and assign token again
    // it is also needed for sessionTokenExchange function
    await setupAuthJs()
    token = getAccessToken()

    const isAdaEmbeded = document.getElementById('ada-entry')

    if (!user && tempToken) {
      try {
        const response = await userApi.convertExchangeToken(tempToken)
        setAccessToken(response.token)
        refreshAccessToken(response.token)

        const authenticatedUser = await getAuthenticatedUser()

        if (!authenticatedUser) {
          if (status === 'loading') setStatus('unauthorized')
          router.replace(router.pathname, undefined, { shallow: true })
          return false
        }
        if (status === 'loading') setStatus('authorized')

        router.replace(PROTECTED_PAGES_PATHS.home, undefined, {
          shallow: true,
        })
        /**
         * @description: Remove the temp token from the url, but keep the other search params
         */
        const params = new URLSearchParams(window.location.search)
        params.delete('t')
        router.replace(
          `${window.location.pathname}${params ? `?${params}` : ''}`,
          undefined,
          {
            shallow: true,
          }
        )

        return true
      } catch (err) {
        console.error('Error =>', err)
        setStatus('unauthorized')
        return false
      }
    }

    if (!validateToken(token)) {
      if (isAdaEmbeded) {
        adaEmbed.stop()
      }
      // clear DD user
      observabilityUtils.browserRum.clearUser()

      // set status to logged out
      if (status === 'loading') setStatus('unauthorized')
      return false
    }

    // check if `mfaOptions` exists in sessionStorage
    const mfaOptions = sessionStorageApi.get('mfaOptions')
    // if `mfaOptions` exists, we don't want to fetch user profile yet
    if (mfaOptions) {
      // set status to `unauthorized`
      setStatus('unauthorized')
      return false
    }

    if (status === 'authorized' && user) {
      return true
    }
    const authenticatedUser = await getAuthenticatedUser()
    if (!authenticatedUser) {
      if (status === 'loading') setStatus('unauthorized')
      return false
    }

    // set status to logged in
    if (status === 'loading') setStatus('authorized')

    return true
  }, [
    getAuthenticatedUser,
    router,
    setAccessToken,
    setStatus,
    status,
    tempToken,
    token,
    user,
  ])

  useEffect(() => {
    if (!router.isReady) {
      return
    }
    if (status === 'loading' && !user) {
      checkAuth()
    }
  }, [status, user, router.isReady, checkAuth])

  return { user, status, token }
}

export { useAuth }
