import Image from 'next/image'
import styled from 'styled-components'
import moneyLionLogo from '/public/assets/svgs/ml-logo-tagline.svg'
import moneyLionLogoWhite from '/public/assets/svgs/ml-logo-tagline-white.svg'
import { useUIStore } from '@root/store'

const ContainerDiv = styled.div`
  z-index: 999999;
  background-color: ${({ theme }) => theme.colors['background-primary']};
  position: absolute;
  width: 100%;
  height: 100%;

  .moneylion-logo {
    width: 100%;
    max-width: 220px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const Spinner = (): JSX.Element => {
  const showDarkMode = useUIStore((state) => state.showDarkMode)
  return (
    <ContainerDiv id="loading-indicator">
      <Image
        src={showDarkMode ? moneyLionLogoWhite : moneyLionLogo}
        alt="MoneyLion Logo"
        className="moneylion-logo"
        priority
      />
    </ContainerDiv>
  )
}

export default Spinner
